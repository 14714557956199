/* @font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./Assets/Font/Inter-VariableFont_slntwght.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url(./Assets/Font/Inter-VariableFont_slntwght.ttf) format('truetype');
} */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
  width: 9px;
}

div::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03b4c6 !important;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #205878;
}
